import React, { Component } from "react"
import propTypes from 'prop-types'
import { css } from '@emotion/react'

import { CataloguesService } from '../../services/LandingV4'

import { Container, Typography, Grid, Backdrop, Snackbar, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { SmsFailed, MobileFriendly } from '@material-ui/icons';

import { ButtonDegrade, CardElevation, InputNip } from '@findep/mf-landings-core'

import { reSendSignatureBFF, verifySignatureBFF, abort, signatureV2, verificationsPhoneV2 } from '../../lib/saveData'

import { navigate } from "../../helpers/queryNavigate"
import NameCompany from '../../helpers/NameCompany'

import ButtonDegradeFirma from '../elements/ButtonDegradefirma'



const helpTxt = css` 
  color: #bf0000;
`
const backDropCss = css`
  z-index: 1000;
`
const root = css`
  display: flex;
  width: 100%;
`
const iconRoot = css`
  color: rgba(0,0,0,0.26);
  font-size: 2.2rem !important;
  margin-top: 0.8rem;
`
const containerSelct = css`
  display: flex;
  flex-direction: column;
  text-align: justify;
  width: 80% !important;
`


class FirmaDigitalComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: '',
      banderaConfirmation: this.props.banderaConfirmation,
      btnNextEnable: false,
      checkState: false,
      creditApplicationId: '',
      code: '',
      continuar: false,
      codesequals: false,
      correctCode: false,
      count: 70,
      confirmation: '',
      countnosms: 15,
      errorService: false,
      former: false,
      flujo: {},
      isPhoneVerification: true,
      legales: [],
      loading: false,
      mensaje: '',
      mobileNumber: 0,
      namesCompany: NameCompany(this.props.companyName),
      payment: '',
      paymentPeriod: '',
      secondsToWait: 0,
      siguientePago: '',
      success: false,
      switchButton: false,
      terms: '',
      timeout: false,
      timeoutnosms: false,
    }

    this.handleStartStop = this.handleStartStop.bind(this);
    this.handleStartStopnosms = this.handleStartStopnosms.bind(this);
    this.handleStartStop()
    this.handleStartStopnosms()
  }

  async componentDidMount() {
    const flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
    let id = flujo?.creditApplicationId

    let urlPathname = window.location.pathname
    if (urlPathname.indexOf('former') >= 0) {
      this.setState({ former: true })
    }

    const catalogos = new CataloguesService()
    const legal = await catalogos.getLegales(id)
    this.setState({ legales: legal === undefined ? [] : legal.data.legales })
    this.setState({ creditApplicationId: id })
    const catData = await catalogos.getSignature(id)
    const { mobileNumber, secondsToWait, forceRedirect, amount, payment, paymentPeriod, terms, nextPayment } = catData.data
    /**Refactorizar ya se usa en varios js */
    var meses = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
    let f = new Date(nextPayment)
    let arrFecha = `${f.getDate()}-${meses[f.getMonth()]}-${f.getFullYear()}`
    /** */
    this.setState({ amount })
    this.setState({ payment })
    this.setState({ paymentPeriod })
    this.setState({ terms })
    this.setState({ siguientePago: arrFecha })
    this.setState({ secondsToWait })
    this.setState({ mobileNumber })
    this.setState({ count: secondsToWait })

    if (forceRedirect !== null && forceRedirect !== undefined) {
      navigate(forceRedirect)
    }
  }

  handleStartStop() {
    this.timer = setInterval(() => this.setState(prevState => {
      if (prevState.count === 0) return null;

      return {
        count: prevState.count - 1,
      };
    }), 1000);
  }

  handleStartStopnosms() {
    this.timer = setInterval(() => this.setState(prevState => {
      if (prevState.countnosms === 0) return null;

      return {
        countnosms: prevState.countnosms - 1,
      };
    }), 1000);
  }

  async componentDidUpdate(prevProp, prevState) {
    const {
      checkState,
      code,
      codesequals,
      confirmation,
      continuar,
      switchButton,
    } = this.state

    if (this.state.isPhoneVerification) {
      if (prevState.continuar !== continuar) {
        if (continuar) {
          this.setState({ btnNextEnable: true })
        } else {
          this.setState({ btnNextEnable: false })
        }
      }
    } else {
      if (prevState.continuar !== continuar || prevState.checkState !== checkState) {
        if (continuar && checkState) {
          this.setState({ btnNextEnable: true })
        } else {
          this.setState({ btnNextEnable: false })
        }
      }
    }

    if (prevState.count !== this.state.count) {
      if (this.state.count === 0 && !this.state.correctCode) {
        this.setState({ timeout: true })
      }
    }

    if (prevState.countnosms !== this.state.countnosms) {
      if (this.state.countnosms === 0) {
        this.setState({ timeoutnosms: true })
      }
    }

    if ((code.length === 6) &&
      ((prevState.confirmation !== confirmation) || (prevState.code !== code)) &&
      (code !== confirmation) && (confirmation.length === 6)) {

      this.setState({
        errorService: true,
        mensaje: "Los códigos no coinciden",
        // switchButton: false,
      })
    }

    if ((code.length === 6) && (prevState.confirmation !== confirmation) && (code === confirmation)) {
      this.setState({ switchButton: true })
    }

    if (((code.length < 6 && code !== "") || (confirmation.length < 6 && confirmation !== "")) && (prevState.confirmation !== confirmation)) {
      this.setState({ switchButton: false })
    }

    if (prevState.switchButton !== switchButton) {
      this.setState({ switchButton: switchButton })
    }

    /**Habilita/deshabilita boton de buro nuevo */
    if (this.state.isPhoneVerification) {
      if (confirmation && code && (prevState.code !== code || prevState.confirmation !== confirmation)) {
        if (code.length === 6 && confirmation.length === 6 && code === confirmation) {
          // && codesequals
          this.setState({ btnNextEnable: true })
        } else {
          this.setState({ btnNextEnable: false })
        }
      }
    } else {
      if (confirmation && code && (prevState.code !== code || prevState.confirmation !== confirmation || prevState.checkState !== checkState)) {
        if (code.length === 6 && confirmation.length === 6 && checkState && code === confirmation) {
          // && codesequals
          this.setState({ btnNextEnable: true })
        } else {
          this.setState({ btnNextEnable: false })
        }
      }
    }

  }

  handleChange = async (e) => {
    this.setState({ loading: true })
    await verifySignatureBFF(e.value, this)
  }

  handleChangeCode = async (e) => {
    this.setState({ code: e.value })
  }

  handleChangeConfirmation = async (e) => {
    this.setState({ confirmation: e.value })
  }

  eventoContinuar = () => {
    navigate(this.props.pageContext.next.path)
  }

  handleNextStep = async () => {
    this.setState({
      openBackDrop: true
    })

    this.setState({ loading: true })

    await signatureV2(this.state.code, this.state.confirmation, this.state.creditApplicationId, this)
  }

  eventoEnviarMensaje = async () => {
    this.setState({ loading: true })
    this.setState({ timeout: false })
    this.setState({ count: this.state.secondsToWait })
    await reSendSignatureBFF(this)
  }

  handleChangeCheck = (event) => {
    this.setState({ checkState: event })
  };

  eventoLink = async () => {
    this.setState({ loading: true })
    await abort(this)
  }

  render() {
    const {
      banderaConfirmation,
      btnNextEnable,
      checkState,
      continuar,
      count,
      flujo,
      legales,
      mobileNumber: telefono,
      timeout,
      timeoutnosms
    } = this.state

    let texto = count > 0 ? `   REACTIVANDO EN:  ${count}` : `REENVIAR CÓDIGO AL: ${telefono}`
    let textonosms = `NO HE RECIBIDO NINGÚN SMS`
    let loader = CircularProgress

    return (
      <Container>
        <br />

        <CardElevation>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6">
                "Te enviamos un SMS con un NIP que servirá para validar tu número telefónico"
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <ButtonDegradeFirma
                id="firma-digital-enviar-codigo-button"
                disabled={!timeout}
                icon={this.state.count > 0 ? loader : MobileFriendly}
                textButton={texto}
                onClick={this.eventoEnviarMensaje} />
            </Grid>

            {
              timeoutnosms &&

              <Grid item xs={12}>
                <ButtonDegradeFirma
                  colorTheme="warning"
                  id="firma-digital-no-sms-button"
                  disabled={!timeoutnosms}
                  icon={SmsFailed}
                  textButton={textonosms}
                  onClick={() => { this.eventoLink() }}
                />
              </Grid>
            }

            <Grid item xs={12}>
              <Typography variant="p" component="p">
                Debes ingresar el último código que recibiste vía SMS,
                en los siguientes campos, si no lo recibiste haz click en el botón para reenviarlo.
              </Typography>
            </Grid>

            {
              !banderaConfirmation
                ?
                <>
                  <Grid item xs={12}>
                    <InputNip
                      autofocus
                      length={6}
                      onChange={this.handleChange}
                      id="firma-digital-codigo"
                      disabled={continuar} />
                  </Grid>
                </>
                :
                <>
                  <Grid item xs={12}>
                    <InputNip
                      autofocus
                      emitForever
                      length={6}
                      onChange={this.handleChangeCode}
                      id="tu-historial-codigo"
                      disabled={this.state.continuar} />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="p" component="p">
                      Vuelve a ingresar el código que llego por SMS a continuación.
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <InputNip
                      emitForever
                      length={6}
                      onChange={this.handleChangeConfirmation}
                      id="tu-historial-confirmation"
                      disabled={this.state.continuar}
                    />
                  </Grid>
                </>
            }
          </Grid>
        </CardElevation>

        <br />

        <Grid container>
          <Grid item style={{ textAlign: "right" }} xs={12}>

            <br />

            <ButtonDegrade
              id="firma-digital-continuar-button"
              disabled={!btnNextEnable}
              icon={MobileFriendly}
              textButton='CONTINUAR'
              onClick={!banderaConfirmation ? this.eventoContinuar : this.handleNextStep} />
          </Grid>
        </Grid>

        <Backdrop open={this.state.loading} css={backDropCss}>
          <CircularProgress color="primary" />
        </Backdrop>

        <Snackbar open={this.state.errorService} autoHideDuration={6000} onClose={() => this.setState({ errorService: false })}>
          <Alert variant="filled" severity="warning" onClose={() => this.setState({ errorService: false })}>
            {this.state.mensaje}
          </Alert>
        </Snackbar>

        <Snackbar open={this.state.success} autoHideDuration={3000} onClose={() => this.setState({ success: false })}>
          <Alert variant="filled" severity="success" onClose={() => this.setState({ success: false })}>
            {this.state.mensaje}
          </Alert>
        </Snackbar>
      </Container>
    );
  }
}

FirmaDigitalComponent.propTypes = {
  banderaConfirmation: propTypes.bool
};

FirmaDigitalComponent.defaultProps = {
  banderaConfirmation: false
}

export default FirmaDigitalComponent;